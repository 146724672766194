import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from './Pages/Login/Index'
import "./App.css"
import Signup from './Pages/Signup/Index'
import Forgot from './Pages/Forgot/Index'
import OTP from './Pages/OTP/Index'
import PasswordReEnter from './Pages/PasswordReenter/Index'
import ChooseSupplier from './Pages/ChooseSupplier/Index'
import UserHome from './Pages/Home/Index'
import Notification from './Pages/Notification/Index'
import History from './Pages/History/Index'
import GetHelp from './Pages/GetHelp/Index'
import ProfileEdit from './Pages/ProfileEdit/Index'
import Logout from './Pages/Logout'
import PcScreen from './Components/PcScreen'
import QrCode from './Pages/QrCode/Index'
function App() {
  const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : ""
  const _id = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : ""
  return (
    <>
      <div id='mobileVersion'>
        <BrowserRouter>
          <Routes>
            <Route path='/login' element={(token === "" && _id === "") ? <Login /> : <Navigate to="/" />} />
            <Route path='/signup' element={(token === "" && _id === "") ? <Signup /> : <Navigate to="/" />} />
            <Route path='/forgot' element={(token === "" && _id === "") ? <Forgot /> : <Navigate to="/" />} />
            <Route path='/otp' element={(token === "" && _id === "") ? <OTP /> : <Navigate to="/" />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/changepassword' element={(token === "" && _id === "") ? <PasswordReEnter /> : <Navigate to="/" />} />
            <Route path='/help' element={(token !== "" && _id !== "") ? <GetHelp /> : <Navigate to="/" />} />
            <Route path='/editprofile' element={(token !== "" && _id !== "") ? <ProfileEdit /> : <Navigate to="/login" />} />


            <Route path='/supplierchoose' element={(token !== "" && _id !== "") ? <ChooseSupplier /> : <Navigate to={"/login"} />} />


            <Route path='/' element={(token !== "" && _id !== "") ? <UserHome /> : <Navigate to="/login" />} />
            <Route path='/notification' element={(token !== "" && _id !== "") ? <Notification /> : <Navigate to="/login" />} />
            <Route path='/history' element={(token !== "" && _id !== "") ? <History /> : <Navigate to="/login" />} />
            <Route path='/qrcode' element={(token !== "" && _id !== "") ? <QrCode /> : <Navigate to="/login" />} />

          </Routes>
        </BrowserRouter>
      </div>
      <PcScreen />
    </>
  )
}

export default App