const auth = {
    signup: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/signup",
    signupOtp: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/verify-signup",
    login: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/login",
    getSuppliers: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/get-all-destributor",
    addSupplier: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/add-distributor",
    resendOtp: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/resend-otp",
    forgotOtp: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/forget-password/send-otp",
    forgotPassword: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/forget-password",
    getProfile: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/profile-get",
    getTransaction: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/get-all-transaction",
    editProfile: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/profile-edit",
    getSliderStatus: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/get-lock-status",
    updateSliderStatus: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/update-lock",
    getAllTransaction: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/get-all-transaction",
}

export default auth;