import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const ChooseSupplier = () => {
    const navigate = useNavigate()
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : ""
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [supplier, setSupplier] = useState({
        supplier: ""
    })
    const [supplierList, setSupplierList] = useState(null)
    const getSuppliers = () => {
        setLoaderStatus(true)
        axios.get(auth.getSuppliers, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            setLoaderStatus(false)
            if (res.status !== 202) {
                setSupplierList(res.data.data)
            }
        }).catch((err) => {
            setLoaderStatus(false)
            toast.error("Please try again later")
            console.log(err);
        })
    }
    useEffect(() => {
        // eslint-disable-next-line
        getSuppliers()
    }, [])

    const AddSupplier = (e) => {
        e.preventDefault()
        const filterName = supplierList && supplierList.filter((data) => {
            return data.name === supplier.supplier
        })
        setLoaderStatus(true)
        axios.put(auth.addSupplier, { userId, distributorId: filterName[0]._id }, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            setLoaderStatus(false)
            if (res.status !== 202) {
                toast.success(res.data.message)
                navigate("/", { state: { supplier: true } })
            }
        }).catch((err) => {
            setLoaderStatus(false)
            toast.error("Please try again later")
            console.log(err);
        })
    }

    const _this = {
        supplier, setSupplier, supplierList, AddSupplier, loaderStatus
    }
    return (
        <Body {..._this} />
    )
}

export default ChooseSupplier