import React from 'react'
import Header from '../../Components/Header'
import "../../Assets/Style/Pages/Notification.css"
import UnderProgressImg from '../../Assets/Images/underProgress.png'
// import ListCard from '../../Components/ListCard'
const Body = _props => {
    return (
        <div className='notificationPage'>
            <Header headerLabel='Notification' />
            <div className="notificationPageBody">
                <div className="notificationMarkBtnSec">
                    {/* <button className="notificationAllReadBtn">
                        Mark as read
                    </button> */}
                </div>
                {/* <ListCard status='test' date='Today 12:34 pm' title="Daily update for today" qty="3" amount={300} />
                <ListCard status='test' date='Today 12:34 pm' title="Daily update for today" qty="3" amount={300} /> */}
                <div className='UnderConstructionWrapper'>
                    <img src={UnderProgressImg} alt="UnderProgressImg" />
                    <h4>This option is not available at this time</h4>
                </div>


            </div>


        </div>
    )
}

Body.propTypes = {}

export default Body