import React from 'react'
import PropTypes from 'prop-types'

const PaymentDisplayCard = _props => {
    return (
        <div className="CountAndPaymentCards">
            <div className="cardTitle">Due Count</div>
            <div className="cardDetails">
                <div className="cardDetailsNumber">
                    {_props?.data}
                </div>
                <div className="cardDetailsUnit">
                    {_props?.unit}
                </div>
            </div>
            <div className="cardDate">On {_props?.date}</div>
        </div>
    )
}

PaymentDisplayCard.propTypes = {
    data: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired
}

export default PaymentDisplayCard