import React from 'react'
import Header from '../../Components/Header'
import "../../Assets/Style/Pages/History.css"
import ListCard from '../../Components/ListCard'
// import ActiveLoader from '../../Components/ActiveLoader'
import transactionNotFounfImg from '../../Assets/Images/transactionNotFound.png'
const Body = (_props) => {



    return (
        _props.transaction !== null ? <div className="historyPage">
            <Header headerLabel='History' />
            <div className="historyPageBody">
                {
                    _props?.transaction && _props?.transaction.TransactionInf.map((data) => {
                        return <ListCard date={data.date} amount={data.amount} qty={data.qty} status={data.status} />
                    })
                }
            </div>
        </div>
            :
            <div>
                <Header headerLabel='History' />
                <div className='transactionNotFounCont'>
                    <img src={transactionNotFounfImg} alt="transactionNotFounfImg" />
                    <p>Tranction not found</p>
                </div>
            </div>
        // <ActiveLoader loaderStatus={true} />
    )
}

export default Body