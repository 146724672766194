import React, { useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState({
        phone: "",
        password: ""
    })
    const [showPassword, setShowPassword] = useState(false)
    const [loader, setLoader] = useState(false)
    const HandleLogin = (e) => {
        e.preventDefault();
        setLoader(true)
        axios.post(auth.login, login).then((res) => {
            setLoader(false)
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                toast.success(res.data.message)
                const oneMonthFromNow = new Date();
                oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

                document.cookie = `token=${res.data.token}; expires=${oneMonthFromNow.toUTCString()}; path=/`;
                document.cookie = `id=${res.data.userData._id}; expires=${oneMonthFromNow.toUTCString()}; path=/`;
                if (res.data.userData.distributor) {
                    navigate("/", { state: { supplier: true } })
                } else {
                    navigate("/supplierchoose")
                }
                window.location.reload()
            }
        }).catch((err) => {
            setLoader(false)
            toast.error("Please try again after some time")
            console.log(err);
        })
    }
    const _this = {
        login, setLogin, showPassword, setShowPassword, HandleLogin,loader
    }
    return (
        <Body {..._this} />
    )
}


export default Login