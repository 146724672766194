import React from 'react'
import "../Assets/Style/Components/FormPagesSvgStyle.css"
import PropTypes from "prop-types"
const FormPagesSvgStyle = (_props) => {
    const [imageWidth, imageHeight] = _props?.imageSize;
    const [color1, color2, color3] = _props?.circleColor
    return (
        <div className={_props?.rotation !== 0 ? "userFormCirculerStyleSec circularStyleRotation" : "userFormCirculerStyleSec"}>
            <span className="circularDesignCircle" style={{ "--circularDesignCircleColor": color1 }}></span>
            <span className="circularDesignCircle" style={{ "--circularDesignCircleColor": color2 }}></span>
            <span className="circularDesignCircle" style={{ "--circularDesignCircleColor": color3 }}></span>



            {
                _props?.imageOrder === 1 ?
                    <>
                        <p style={{ fontSize: _props?.textSize + "px" }} className='circularStyleText'>{_props?.text}</p>
                        <img src={_props?.image} alt='smile' style={{ width: imageWidth, height: imageHeight }} className='circularStyleImage' />
                    </>
                    :
                    <>

                        <img src={_props?.image} alt="logo" style={{ width: imageWidth, height: imageHeight }} className='circularStyleImage' />
                        <p style={{ fontSize: _props?.textSize + "px" }} className='circularStyleText'>{_props?.text}</p>
                    </>
            }


        </div>
    )
}

export default FormPagesSvgStyle


FormPagesSvgStyle.defaultProps = {
    rotation: 0
}
FormPagesSvgStyle.propTypes = {
    imageSize:PropTypes.array.isRequired,
    circleColor: PropTypes.array.isRequired,
    text:PropTypes.string.isRequired,
    image:PropTypes.string.isRequired,
    imageOrder:PropTypes.number.isRequired,
    textSize:PropTypes.number.isRequired
}