import React from 'react'
import "../../Assets/Style/Pages/Login.css"
import InputBox from '../../Components/InputBox'
import { Link } from 'react-router-dom'
import smileImg from "../../Assets/Images/emoji _smiling_face_with_smiling_eyes.png"
import qritLogo from "../../Assets/Images/Qrit logo transparent.png"
import FormPagesSvgStyle from '../../Components/FormPagesSvgStyle'
import { Toaster } from 'react-hot-toast'
import ActiveLoader from '../../Components/ActiveLoader'
const Body = (_props) => {
    return (
        <>
            <ActiveLoader loaderStatus={_props?.loader} />
            <Toaster />
            <div className="loginPage">
                <FormPagesSvgStyle imageOrder={1} image={smileImg} imageSize={[42, 42]} circleColor={["#0077FF", "#84BEFF", "#BEDBFD"]} text={"Well done, Continue!"} textSize={30} />


                <form className="loginForm" onSubmit={(e) => _props?.HandleLogin(e)}>
                    <InputBox
                        type="number"
                        isPassword={false}
                        {..._props}
                        inputLabel="Phone No."
                        placeholder="Enter your phone number"
                        name="phone"
                        inputValue={_props?.login.phone}
                        onInputChange={_props?.setLogin}
                        inputParse={_props?.login}
                    />



                    <InputBox
                        type="password"
                        {..._props}
                        isPassword={true}
                        inputLabel="Password" placeholder="Enter your Password" name="password"
                        inputValue={_props?.login.password}
                        onInputChange={_props?.setLogin}
                        inputParse={_props?.login}
                    />


                    <div className="formError">
                        {
                            (_props?.login.phone.split("").length !== 10 && _props?.login.phone !== "") ? "Phone no must be 10 digit" : ""
                        }
                    </div>

                    <button type='submit' className="submitBtn" disabled={(_props?.login.phone !== "" && _props?.login.password !== "" && _props?.login.phone.split("").length === 10) ? false : true}>Login</button>
                    <div className="signUpsection">
                        <Link to={"/forgot"}>Forgot Password</Link>
                        <div className="flex-row g-1">Do not Sign up? <Link to={"/signup"}>
                            Sign up
                        </Link>
                        </div>
                    </div>
                </form>
                <FormPagesSvgStyle imageOrder={2} rotation={180} image={qritLogo} imageSize={[80, 80]} circleColor={["#0077FFF0", "#00AEB93D", "#020C0D12"]} text={"Made with love ❤️"} textSize={15} />
            </div>

        </>
    )
}

export default Body