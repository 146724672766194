import React, { useEffect, useState } from 'react'
import "../../Assets/Style/Pages/ChooseSupplier.css"
import FormPagesSvgStyle from '../../Components/FormPagesSvgStyle'
import SmileImg from "../../Assets/Images/emoji _smiling_face_with_smiling_eyes.png"
import qritLogoImg from "../../Assets/Images/Qrit logo transparent.png"
import InputBox from '../../Components/InputBox'
import { Toaster } from 'react-hot-toast'
import ActiveLoader from '../../Components/ActiveLoader.jsx'

const Body = (_props) => {
    const [suppliers, setSuppliers] = useState(null);
    useEffect(() => {
        const outputSupplierArray = _props?.supplierList && _props?.supplierList.map((data) => {
            return data.name
        })
        setSuppliers(outputSupplierArray)
    }, [_props?.supplierList])
    return (
        suppliers &&

        <div className="supplierChooseSec">
            <ActiveLoader loaderStatus={_props?.loaderStatus} />
            <Toaster />
            <FormPagesSvgStyle text='You should choose here!' textSize={27} imageOrder={1} image={SmileImg} imageSize={[42, ""]} circleColor={["#0077FF", "#84BEFF", "#BEDBFD"]} />

            <form className="supplierChooseForm" onSubmit={(e) => _props?.AddSupplier(e)}>

                <InputBox type='select' name='supplier' placeholder='Choose Your supplier' inputLabel='Choose supplier' inputValue={_props?.supplier.supplier} onInputChange={_props?.setSupplier} inputParse={_props?.supplier} selectItems={suppliers} isrequired={true} />
                <button className="submitBtn">Next</button>
            </form>

            <FormPagesSvgStyle imageOrder={2} rotation={180} text='Made with love ❤️' textSize={14} image={qritLogoImg} imageSize={[90, ""]} circleColor={["#0077FF", "#84BEFF", "#BEDBFD"]} />
        </div>
    )
}

export default Body