import { React, useState, useEffect } from 'react'
import Body from './Body'

import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'
const History = () => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : ""
    const [transaction, setTransaction] = useState(null)
    useEffect(() => {
        axios.get(auth.getAllTransaction + "/" + userId, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error);
            } else {
                setTransaction(res.data)
            }
        }).catch((err) => {
            console.log((err));
        })
    }, [])
    


    const _this = {transaction}
    return (
        <Body {..._this} />
    )
}

export default History