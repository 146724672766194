import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const Signup = () => {
    const navigate = useNavigate()
    const [loaderStatus, setLoaderStatus] = useState(false)
    const [formError, setFormError] = useState("")

    const [signup, setSignup] = useState({
        name: "",
        email: "",
        phone: "",
        wpNumber: "",
        gender:"",
        occupation: "",
        address: "",
        pincode: "",
        password: "",
        cpassword: "",
        termCondition: false,
        type: "Registration"
    })
    // validate password and confirm password 
    useEffect(() => {
        signup.password !== signup.cpassword ? setFormError("Password and confirm password must be same") : setFormError("")
    }, [signup])

    // assign all states to a single object and pass through a single props
    const SignupHandle = (event) => {
        event.preventDefault()
        setLoaderStatus(true)
        axios.post(auth.signup, signup).then((res) => {
            if (res.status === 202) {
                setLoaderStatus(false)
                toast.error(res.data.error)

            } else {
                setLoaderStatus(false)
                toast.success("Otp Sended Successfully !")
                navigate("/otp", { state: { ...signup } })
            }
        }).catch((err) => {
            setLoaderStatus(false)
            toast.error("Please try after some while")
            console.log(err.data)
        })
    }

    const _this = {
        signup, setSignup, formError, setFormError, SignupHandle
        , loaderStatus
    }

    return (
        <Body {..._this} />
    )
}

export default Signup	