import React from 'react'
import PropTypes from 'prop-types'
import "../Assets/Style/Components/ListCard.css"
const ListCard = _props => {
    return (
        <>
            <div className="listCard">
                <div className="listCardHistoryLeft">
                    <div>
                        <span>{_props?.qty}</span>

                        <span>p</span>

                    </div>
                    <div>
                        <span>{_props?.amount}</span>

                        <span>₹</span>

                    </div>
                </div>
                <div className="listCardHistoryRight">
                    <div className="listcardDate">
                        {_props?.date}
                    </div>
                    <div className="listCardStatus" style={{ "--listCardStatusColor": (_props?.status === "paid") ? "green" : "red" }}>
                        {_props?.status}
                    </div>
                </div>
            </div>
        </>
    )
}

ListCard.propTypes = {
    date: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
}

export default ListCard