import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'

const UserHome = () => {

    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : ""
    const [profile, setProfile] = useState(null)
    const [transaction, setTransaction] = useState(null)


    const getProfile = () => {
        axios.get(auth.getProfile + "/" + userId, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setProfile(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const fetchTransaction = () => {
        axios.get(auth.getTransaction + "/" + userId, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((res) => {
            if (res.status === 202) {
                if (res.data.error!=='Transaction Not found') {
                    toast.error(res.data.error)
                }
            } else {
                setTransaction(res.data)

            }
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        getProfile()
        fetchTransaction()
    }, [])




    const _this = { profile, transaction }
    return (
        <Body {..._this} />
    )
}

export default UserHome