import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import lockIcon from '../Assets/Images/lock.svg';
import unlockIcon from "../Assets/Images/unlock.svg"
import axios from 'axios';
import auth from '../Routes/Auth/Auth';
import toast, { Toaster } from 'react-hot-toast';
import ActiveLoader from './ActiveLoader';

const Slider = (_props) => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : ""
    const [loader, setLoader] = useState(false)
    const [isUnlocked, setIsUnlocked] = useState(false);

    const updateSwip = () => {
        setLoader(true)
        axios.put(auth.updateSliderStatus, { userId, lock: !isUnlocked }, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
            setLoader(false)
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setIsUnlocked(res.data.data.lock)
            }
        }).catch((err) => {
            setLoader(false)
            console.log(err);
        })
    }
    const customSwipeRightHandler = async () => {
        await setIsUnlocked(true);
        updateSwip()
    };

    const customSwipeLeftHandler = async () => {
        await setIsUnlocked(false);
        updateSwip()
    };

    const handlers = useSwipeable({
        onSwipedRight: customSwipeRightHandler,
        onSwipedLeft: customSwipeLeftHandler,
    });
    useEffect(() => {
        setLoader(true)
        axios.get(auth.getSliderStatus + "/" + userId, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
            setLoader(false)
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setIsUnlocked(res.data.data.lock)

            }
        }).catch((err) => {
            setLoader(false)
            console.log(err);
        })
    }, [])


    return (
        <div {...handlers} style={styles.container}>
            <ActiveLoader loaderStatus={loader} />
            <Toaster />
            <div style={styles.swipeArea}>
                <div
                    style={{
                        ...styles.lockIcon,
                        left: isUnlocked ? '12px' : '258px',
                        backgroundImage: `url(${isUnlocked ? lockIcon : unlockIcon})`,
                        backgroundColor: isUnlocked ? '#ff4081' : '#4caf50',
                    }}
                />
                <div style={{
                    ...styles.text,
                    textAlign: isUnlocked ? 'right' : 'center',
                    left: isUnlocked ? '-50px' : '0',
                }}>
                    {isUnlocked ? 'Swipe right to Unlock' : 'Unlocked'}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '6rem',

        // backgroundColor: '#f0f0f0',
    },
    swipeArea: {
        width: '350px', // Updated width
        height: '100px',
        backgroundColor: '#fff',
        borderRadius: '50px',
        position: 'relative',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    lockIcon: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        transition: 'left 0.3s ease',
    },
    text: {
        position: 'absolute',
        width: '100%',
        color: '#333',
        fontSize: '18px',
        top: '50%',
        fontWeight: 'bold',
        transform: 'translateY(-50%)',
        transition: 'all 0.3s ease',
    },
};

export default Slider;
