import React, { useState, useEffect } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'


const Index = () => {

  const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : ""
  const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : ""
  const [profile, setProfile] = useState(null)


  const getProfile = () => {
    axios.get(auth.getProfile + "/" + userId, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then((res) => {
      if (res.status === 202) {
        toast.error(res.data.error)
      } else {
        setProfile(res.data.data)
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _this = { profile }
  return (
    <Body {..._this} />
  )
}

export default Index
