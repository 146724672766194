import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import "../Assets/Style/Pages/FilterWaterDetails.css"
import { IoCalendarOutline } from "react-icons/io5"
const FilterWaterDetails = props => {
    const today = new Date();
    const [dateChange, setDateChange] = useState("")
    return (
        <div className="filterWaterDetails">
            <div className="filterWaterDetailsInnerSec">


                <div className="cardDateFilterHeader">
                    <label htmlFor="filterDate" className='monthViewLabel'>
                        {
                            (dateChange === "" || new Date(dateChange).getMonth() === today.getMonth()) ? "This Month" : new Date(dateChange).toLocaleString('default', { month: 'long' })
                        }
                    </label>
                    <div className="filterDate">
                        <input type="date" id='filterDate' onChange={(e) => setDateChange(e.target.value)} value={dateChange} placeholder={""} />
                        <button className='dateToggleBtn'><IoCalendarOutline size={35} color='gray' /></button>
                    </div>
                </div>

                <div className="measurementCards">
                    <div className="mesurementCard">
                        <div className="measureNumber">0</div>
                        <div className="measureUnit">L</div>
                    </div>
                    <div className="mesurementCard">
                        <div className="measureNumber">0</div>
                        <div className="measureUnit">L</div>
                    </div>
                    <div className="mesurementCard">
                        <div className="measureNumber">0</div>
                        <div className="measureUnit">L</div>
                    </div>
                    <div className="mesurementCard">
                        <div className="measureNumber">0</div>
                        <div className="measureUnit">L</div>
                    </div>
                </div>


            </div>
        </div>
    )
}

FilterWaterDetails.propTypes = {


}

export default FilterWaterDetails