import React from 'react'
import "../../Assets/Style/Pages/ProfileEdit.css"
import Header from '../../Components/Header'
import profileImg from "../../Assets/Images/profileLogoTest.png"
import { MdOutlineCameraAlt } from "react-icons/md"
import EditableInput from '../../Components/EditableInput'
import ActiveLoader from '../../Components/ActiveLoader'
import { Toaster } from 'react-hot-toast'
const Body = (_props) => {
    return (
        <>
            <ActiveLoader loaderStatus={_props?.loader} />
            <Toaster />
            <div className="profileEditPage">

                <Header headerLabel='My Account' />
                <div className="profileEditBody">
                    <form className="profileUpdateForm" onSubmit={(e) => _props?.handelEditProfile(e)}>
                        <div className="profileHeader">
                            <img src={profileImg} alt="" />
                            <button className="pictureSelectBtn" type='button'>
                                <MdOutlineCameraAlt size={20} color='black' />
                            </button>
                        </div>
                        <div className="profileUpdateInputs">
                            <div className="userIdHeader">
                                {_props?.updateProfile && _props?.updateProfile.userId}
                            </div>

                            <div className="inputsSection">
                                <EditableInput name="supplierName" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.supplierName} onInputChange={_props?.setUpdateProfile} placeholder="Choose your supplier" maxLength={14} minLength={6} parser={_props?.updateProfile && _props?.updateProfile} label="Supplier Name" />

                                <EditableInput name="supplierId" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.supplierId} onInputChange={_props?.setUpdateProfile} placeholder="Supplier Id" parser={_props?.updateProfile && _props?.updateProfile} label="Supplier Id" />

                                <EditableInput name="name" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.name} onInputChange={_props?.setUpdateProfile} placeholder="Enter your name" parser={_props?.updateProfile && _props?.updateProfile} label="Name" isEdit={true} />
                                <EditableInput name="email" type="email" inputValue={_props?.updateProfile && _props?.updateProfile.email} onInputChange={_props?.setUpdateProfile} placeholder="Enter your email" parser={_props?.updateProfile && _props?.updateProfile} label="Email" isEdit={true} />
                                <EditableInput name="phone" type="number" inputValue={_props?.updateProfile && _props?.updateProfile.phone} onInputChange={_props?.setUpdateProfile} placeholder="Enter your phone no" parser={_props?.updateProfile && _props?.updateProfile} label="Phone No" isEdit={true} />
                                <EditableInput name="wpNumber" type="number" inputValue={_props?.updateProfile && _props?.updateProfile.wpNumber} onInputChange={_props?.setUpdateProfile} placeholder="Enter your whatsapp no" parser={_props?.updateProfile && _props?.updateProfile} label="Whatsapp No" isEdit={true} />


                                <EditableInput name="gender" type="select" inputValue={_props?.updateProfile && _props?.updateProfile.gender} onInputChange={_props?.setUpdateProfile} placeholder="" parser={_props?.updateProfile && _props?.updateProfile} label="Gender" isEdit={true} selectOpt={["Female","Male", "Others"]} />

                                <EditableInput name="occupation" type="select" inputValue={_props?.updateProfile && _props?.updateProfile.occupation} onInputChange={_props?.setUpdateProfile} placeholder="Enter your occupation" parser={_props?.updateProfile && _props?.updateProfile} label="Occupation" selectOpt={["Student","Employed", "Self employed","Unemployed","Commercial"]} isEdit={true}  />
                                <EditableInput name="address" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.address} onInputChange={_props?.setUpdateProfile} placeholder="Enter your address" parser={_props?.updateProfile && _props?.updateProfile} label="Address" isEdit={true} />
                                <EditableInput name="pincode" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.pincode} onInputChange={_props?.setUpdateProfile} placeholder="Enter your pin code" parser={_props?.updateProfile && _props?.updateProfile} label="Pin Code" isEdit={true} />

                                <button className="submitBtn" type='submit'>
                                    Update
                                </button>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Body