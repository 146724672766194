import React from 'react'
import PropTypes from 'prop-types'
import { LiaEdit } from "react-icons/lia"
import { useNavigate } from 'react-router-dom'
const UserProfileDisplay = _props => {
    const navigate=useNavigate();
    return (
        <div className="userProfileSection">
            <div className="profileContents">
                <img src={_props?.image} alt="" className="userProfileImg" />
                <div className="userProfileDataSec">
                    <h2>{_props?.name}</h2>
                    <span className="userProfileAddress">{_props?.address}</span>
                </div>
            </div>
            <div className="profileEditBtnSec">
                <button type='button' onClick={()=>navigate("/editprofile")} className="userProfileEditBtn">
                    <LiaEdit size={30} color='var(--commonColor)' />
                </button>
            </div>
        </div>
    )
}

UserProfileDisplay.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired
}

export default UserProfileDisplay