import React from 'react'
import Header from '../../Components/Header'
import ActiveLoader from '../../Components/ActiveLoader'
import '../../Assets/Style/Pages/QrCode.css'

const Body = (_props) => {

  const BackendUrl = process.env.REACT_APP_BACKEND_URL

  return (
    <div>
      <Header headerLabel='Qr Code' />
      {_props?.profile !== null ?
        <div className='QrCodeWrapper'>
          <h3>Hey, {_props?.profile?.name}</h3>
          <div className='QrCodeContainer'>
            <p>Here is your Distributor QR Code</p>
            <img src={`${BackendUrl}/qr-codes/${_props?.profile?.qrCodeUrl}`} alt="Qr Code" />
          </div>
          <h3>Your Distributor: {_props?.profile?.distributor?.name}</h3>
          <h3>Shop Name: {_props?.profile?.distributor?.storeName}</h3>
        </div> :
        <ActiveLoader loaderStatus={true} />
      }
    </div>
  )
}

export default Body
