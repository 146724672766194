import React from 'react'
import "../Assets/Style/Components/ProfileHeader.css"
import { IoIosNotificationsOutline } from "react-icons/io"
import { MdQrCode } from "react-icons/md";
import PropType from "prop-types"
import { useNavigate } from 'react-router-dom'
const ProfileHeader = (_props) => {
    const navigate = useNavigate();

    return (
        <div className="userHeader">
            <div className="profileName">
                <h2>Hi,</h2>
                <h2>{_props?.profileName}</h2>
            </div>
            <div className="profileActionIcon">
                {_props?.profType === "supplier" ?
                    <img src={_props?.profileIcon} alt="profileIcon" className="profileIcon" />
                    :
                    <div className='qrNotificationWrapper'>
                        <button className='qrcodeBtn' onClick={() => navigate("/qrcode")}>
                            <MdQrCode size={35} />
                        </button>
                        <button className='userProfileNotification' onClick={() => navigate("/notification")}>
                            <IoIosNotificationsOutline size={40} color='black' />
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProfileHeader
ProfileHeader.defaultProps = {
    profileIcon: ""
}

ProfileHeader.profTypes = {
    profileName: PropType.string.isRequired,
    profType: PropType.string.isRequired
}