import React from 'react'
import mobileScreen from '../Assets/Images/mobileScreen.png'

const PcScreen = () => {
    return (
        <div id='PcVersion'>
            <img src={mobileScreen} alt="Screen" />
            <p>Use Mobile for Better Experience</p>
        </div>
    )
}

export default PcScreen
