import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'

const ProfileEdit = () => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : ""

    const [updateProfile, setUpdateProfile] = useState(null)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        axios.get(auth.getProfile + "/" + userId, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setUpdateProfile({
                    userId: res.data.data._id,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    wpNumber: res.data.data.wpNumber,
                    email: res.data.data.email,
                    address: res.data.data.address,
                    occupation: res.data.data.occupation,
                    gender: res.data.data.gender,
                    pincode: res.data.data.pincode,
                    supplierId: res.data.data.distributor._id,
                    supplierName: res.data.data.distributor.name,
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [setUpdateProfile,userId,token])
    const handelEditProfile = (e) => {
        e.preventDefault();
        setLoader(true)
        axios.put(auth.editProfile, updateProfile, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {

            setLoader(false)
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                toast.success(res.data.message)
            }
        }).catch((err) => {
            setLoader(false)
            console.log(err);
            toast.error("Please try again later");
        })

    }

    const _this = { loader, updateProfile, setUpdateProfile, handelEditProfile }
    return (
        <Body {..._this} />
    )
}

export default ProfileEdit