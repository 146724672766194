import React, { useEffect, useState } from 'react'
import "../../Assets/Style/Pages/Home.css"
import { Link } from 'react-router-dom'
import ProfileHeader from '../../Components/ProfileHeader'
import ProfileTestImg from "../../Assets/Images/profileLogoTest.png"
import HistorySecImg from "../../Assets/Images/historySectionImg.png"
import ProfileBanner from "../../Assets/Images/userProfileAddsBanner.jpeg"
import UserProfileDisplay from '../../Components/UserProfileDisplay'
import PaymentDisplayCard from '../../Components/PaymentDisplayCard'
import FilterWaterDetails from '../../Components/FilterWaterDetails'
import { FaArrowRightLong } from "react-icons/fa6"
import { IoIosLogOut } from "react-icons/io"
import { Toaster } from 'react-hot-toast'
import Slider from "../../Components/Slider"
const Body = (_props) => {
    const [dueList, setDueList] = useState({
        totalQuantity: "",
        totalAmount: "",
        dueDate: ""
    })
    const [totalList, setTotalList] = useState({
        totalQuantity: "",
        totalAmount: ""
    })

    useEffect(() => {
        let totalAmount = 0;
        let totalQuantity = 0;
        let dueDate = "";
        _props?.transaction && _props?.transaction.TransactionInf.map((data) => {
            if (data.status === "pending") {
                totalQuantity += data.qty
                totalAmount += data.amount
                dueDate = data.date
            }
        })
        setDueList({ ...dueList, totalAmount: totalAmount, totalQuantity: totalQuantity, dueDate: dueDate })
    }, [_props?.transaction])
    useEffect(() => {
        let totalAmount = 0;
        let totalQuantity = 0;
        _props?.transaction && _props?.transaction.TransactionInf.map((data) => {
            totalQuantity += data.qty
            totalAmount += data.amount

        })
        setTotalList({ ...totalList, totalAmount: totalAmount, totalQuantity: totalQuantity })
    }, [_props?.transaction])



    return (
        <>
            <div className="sliderSection">
                <Slider />
            </div>
            <Toaster />
            <div className="userHomePage">
                <ProfileHeader profType="user" profileIcon={ProfileTestImg} profileName={_props?.profile && _props?.profile.name.split(" ")[0]} />
                <UserProfileDisplay image={ProfileTestImg} name={_props?.profile && _props?.profile.name} address={_props?.profile && _props?.profile.address} />
                <div className="userProfileBanner">
                    <img src={ProfileBanner} alt="banner" />
                </div>
                <div className="paymentsAndCount">
                    <PaymentDisplayCard data={dueList.totalQuantity} unit={"p"} date={dueList.dueDate} />
                    <PaymentDisplayCard data={dueList.totalAmount} unit={"₹"} date={dueList.dueDate} />
                </div>
                <FilterWaterDetails />
                <div className="totalWaterCan">
                    <div className="totalWaterCanCard">
                        <div className="totalWaterCanDetails">
                            <label htmlFor="">Total Count</label>
                            <div className="waterCanCount">
                                <div className="numberOfWaterCan">{totalList.totalQuantity}</div>
                                <div className="unitOfWaterCan">P</div>
                            </div>
                        </div>
                        <div className="totalWaterCanDetails">
                            <label htmlFor="">Total Payment</label>
                            <div className="waterCanCount">
                                <div className="numberOfWaterCan">{totalList.totalAmount}</div>
                                <div className="unitOfWaterCan">₹</div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* user history button and some quote  */}
                <div className="historyVisitOuterSection">
                    <div className="historyVisitInnerCard">
                        <div className="historySecInnerCardLeft">
                            <div className="historySecUserName">
                                Hey &nbsp;
                                {
                                    _props?.profile && _props?.profile.name.split(" ")[0]
                                }
                            </div>
                            <div className="historySecQuote">
                                You can see your transaction and count history
                            </div>
                            <Link className="historyVisitBtn" to={"/history"}>
                                <span>See history</span>
                                <FaArrowRightLong size={20} color="var(--commonColor)" />
                            </Link>
                        </div>
                        <div className="historySecInnerCardImg">
                            <img src={HistorySecImg} alt="" />
                        </div>

                    </div>
                </div>



                <div className="getHelpHomePageSection" style={{ marginBottom: "7.8rem" }}>
                    <Link className="getHelpInnerCard" to={"/help"}>
                        <span>Get help</span>
                        <FaArrowRightLong size={25} color='gray' />
                    </Link>
                    <Link to={"/logout"} className="getHelpInnerCard" style={{ background: "red" }}>
                        <span style={{ color: 'white' }}>Logout</span>
                        <IoIosLogOut size={25} color='white' />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Body